<template>
  <div style="display: flex;flex-direction: column;flex-flow: column;min-height: 100%;margin: 0px auto;">
   
    <div style="display: flex;  flex:0 0 auto;line-height: 60px">
      <div style="width:3OOpx;text-align: center;border-bottom: 1px solid #ffffff; background-color: #ffffff;padding-left:60px">
        <img src="../../assets/logo.png"  style="width: 30px;height:30px;position: relative;top:10px;">招采平台
      </div>
      <!--导航菜单 -->
      <div style="flex: 1">
        <el-menu
          :default-active="activeIndex2"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#ffffff"
          text-color="#000"
          active-text-color="#82c0f1"
          @select="handleSelect"
        >
          <el-menu-item index="1" class="item"><router-link to="/">首页</router-link></el-menu-item>
		 <el-menu-item index="2" class="item"><router-link to="/zhongbiaogonggao">中标公告</router-link></el-menu-item>
		 <el-menu-item index="4" class="item"><router-link to="/news">新闻通知</router-link></el-menu-item>
		 <el-menu-item index="3" class="item"><router-link to="/list">帮助信息</router-link></el-menu-item>
        </el-menu>
      </div>
	<div class="loginorreg" v-if="!user.username" style="text-align:right;background-color: #ffffff;padding-left: 60px" >
      <a @click="$router.push('/login')" style="color: black">登录</a>
      &nbsp;
     <!-- <a  @click="$router.push('/register')" style="color: white">注册</a> -->
    </div>
    <div v-else style="text-align:left;background-color:#ffffff;padding-right: 40px;">
		<el-button ><router-link to="/home">进入管理平台</router-link></el-button>
<!-- 		<el-button ><router-link to="/home">进入管理平台</router-link></el-button> -->
      <el-dropdown style="width: 150px; cursor: pointer; text-align: right">
        <div style="display: inline-block">
            <span style="color: black">个人中心<!-- {{ user.username  }} --></span
            ><i class="el-icon-arrow-down" style="margin-left: 5px;color: black"></i>
        </div>
        <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
          <el-dropdown-item style="font-size: 14px; padding: 5px 0">
            <span style="text-decoration: none" @click="logout">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    </div>
    <div style="padding-bottom: 10px"></div>
    <router-view style="flex: 1 1 auto;width: 100%;height: 100px; margin: 0px auto"> </router-view>
    <footer style="flex: 0 0 auto;background-color: #0d213c;height: 100px;position: fixed;bottom: 0;width: 100%;">
      <div class="footer">
        <div class="bottomText greena">
          <a href="/" target="_blank" rel="nofollow" title="关于我们">关于我们</a> ｜<a href="/" target="_blank" rel="nofollow" title="帮助中心">帮助中心</a> ｜ <a href="/" target="_blank" rel="nofollow" title="版权声明">版权声明</a> ｜<a href="/" target="_blank" rel="nofollow" title="联系我们">联系我们</a>
        </div>
        <p>Copyright © 2022-2023<span></span>.All Rights Reserved</p>
        <p><a rel="nofollow" href="https://beian.miit.gov.cn" target="_blank">备案编号:新ICP备2022000699号-1</a></p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "front",
  data() {
    return {
      activeIndex2: "1",
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{}
    };
  },
  beforeCreate() {},
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    handleSelect() {},
    collapse() {
      // this.$parent.$parent.$parent.$parent.collapse()  // 通过4个 $parent 找到父组件，从而调用其折叠方法
      this.$emit("asideCollapse")
    },
    logout() {
      this.$store.commit("logout")
      this.$message.success("退出成功")
      this.$router.push("/front").catch(() =>{})
    },
    
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.item {
	
  display: block;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  float: left;
}
.item:hover {
  background-color: #ff9b1e;
}
el-menu-demo {
  position: relative;
  margin-left: 100px;
}
/*   */
.footer {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0 40px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}
.loginorreg a{
  cursor: pointer;
}
</style>